#device-container {
    position: relative;
    height: 100vh;
    overflow: auto;
    /* overflow: hidden; */
}

#devices-wrapper {
    height: calc(100vh - 178px);
    overflow: auto;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.registered-device-row {
    text-align: left;
    max-width: 450px;
    min-width: 360px;
}
.registered-device-name,
.registered-device-MAC {
    font-size: 12pt;
}


.registered-device-name {
    width: 200px;
}

.registered-device-MAC {
    width: 173px;
}


/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #devices-wrapper {
        width: 302px;
    }

    #devices-wrapper > .top-button-row {
        grid-template-columns: 270px 30px;
    }

    #device-table {
        max-width: 300px;
        min-width: 300px;
    }

    #device-container >
    .add-edit-wrapper {
        width: 300px;
        height: calc(100vh - 181px);
        overflow: auto;
    }

    #device-container >
    .add-edit-wrapper >
    .add-edit-header {
        width: 300px;
    }

    #device-container >
    .add-edit-wrapper >
    .add-edit-form {
        width: 300px;
    }

    .device-pid-input,
    .device-alias-input,
    .device-username-input,
    .device-site-select,
    .device-role-select {
        width: 300px;
        font-size: 12pt;
    }

    .device-password-wrapper {
        width: 300px;
        grid-template-columns: 149px 149px;
        grid-gap: 0 4px;
    }

    #device-container
    .cs-btn-group {
        margin-bottom: 10px;
    }

    .device-password-wrapper>
    .device-password-input,
    .device-password2-input {
        width: 149px;
    }
}