#footer-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 38px;
    width: 100%;
    background-color: #011a4a;
    color: #27AAE1;
    font-size: .75rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 5px;
}

#footer-wrapper 
img {
    position: relative;
    width: 120px;
    height: auto;
    vertical-align: middle;
    margin-right: 20px;
}

#footer-wrapper 
span {
    vertical-align: middle;
    font-size: 8pt;
}

#footer-content-container {
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;
}