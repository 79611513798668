#zone-container {
    height: 920px;
    position: relative;
    overflow: hidden;
}

#zone-container >
.page-title {
    height: fit-content;
}

.zones-page-wrapper {
    height: calc(100vh - 260px);
    overflow: auto;
}

.return-button-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    width: 360px;
    margin: 0px auto 50px auto;
    margin-bottom: 50px;
}

#back-to-floors {
    margin: 0 auto;
}

#zone-map-wrapper {
    height: calc(100vh - 550px);
    margin: 0 auto;
}

#map-placeholder {
    height: 100%;
    width: 100%;
}

#zone-controls-row {
    max-width: 450px;
    margin: 0 auto;
}

#zone-controls-row >
.top-button-row {
    margin: 10px 32px 0 auto;
}

#zone-table-header {
    display: grid;
    grid-template-columns: 69px 147px 103px 142px;
    max-width: 450px;
    min-width: 360px;
    margin: 0 auto;
    font-size: 12pt;
}

.selected-row, 
.unselected-row {
    display: grid;
    grid-template-columns: 64px 145px 63px 142px;
}

.selected-row {
    margin-top: 30px;
}

#zone-select-label {
    margin-left: 5px;
}

.zone-table-label {
    display: flex;
    flex-direction: row;
    justify-content: left;
    font-weight: 500;
}

#zone-table-wrapper {
    /* height: calc(100vh - 752px); */
    height: 200px;
    max-width: 450px;
    min-width: 360px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 12pt;
}

#zone-table-wrapper >
table {
    width: 586px;
}

.selected-row 
input {
    width: 140px;
    height: 30px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    padding-left: 4px;
}

.selected-row 
select {
    width: 86px;
    height: 30px;
    border-color: lightgray;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
}

.zone-safety-level {
    text-align: left;
    
}

#zone-action-label {
    margin-left: 2px;
}

.selected-zone-actions {
    display: flex;
    flex-direction: row;
}

.selected-zone-btn {
    width: fit-content;
    color: #ffffff;
    font-size: 12pt;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin-left: 3px;
}

.zone-edit-cancel-btn {
    background-image: url('../../images/close_white.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin-left: 43px;
    cursor: pointer;
}

.zone-edit-delete-btn {
    background-image: url('../../images/trashcan.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    /* margin-left: 3px; */
    cursor: pointer;
}

.zone-edit-submit-btn {
    background-image: url('../../images/submit_icon.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    /* margin-left: 3px; */
    cursor: pointer;
}

.zone-edit-calibrate-btn {
    background-image: url('../../images/calibrate_icon.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    /* margin-left: 3px; */
    background-color: #fff;
    cursor: pointer;
}

.zone-select {
    width: 20px;
    height: 20px;
    opacity: .5;
    border-radius: 50%;
    border: 5px solid;
}

.zone-selected {
    width: 20px;
    height: 20px;
    /* opacity: .5; */
    border-radius: 50%;
}

.zone-name {
    float: left;
    word-wrap: none;
}

#zone-edit-btn {
    width: 64px;
    height: 24px;
    border-style: none;
    background-color: #57bc4f;
    color: #fff;
    cursor: pointer;
    margin-left: 2px
}

/* Loading animation */
.loader {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 1;
    width: 32px;
    height: 32px;
    margin: -75px 0 0 -75px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #3498db;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

/* Device Select Modal */
#device-select-form{
    height: 110px;
    padding: 10px;
}

#device-label-wrapper {
    margin: 10px 0 0 32px;
    font-weight: 500;
}

#device-label-wrapper > .device-label{
    font-weight: 400;
}

#zone-device-select select{
    height: 32px;
    width: 200px;
    border: 1px solid black;
    margin-top: 4px;
}

.cancel-calibrate-btn,
.begin-calibrate-btn {
    width: fit-content;
    height: 40px;
    cursor: pointer;
}

.cancel-calibrate-btn {
    background-color: lightgray;
    color: black;
} 

.begin-calibrate-btn {
    background-color: #57bc4f;
}

@media only screen 
    and (min-device-width: 375px) 
    and (max-device-width: 740px) 
    and (-webkit-min-device-pixel-ratio: 2) {

    
    #zone-controls-row >
    .top-button-row {
        width: calc(100vw - 42px);
    }

    #zone-table-wrapper {
        height: 80px;
        margin-bottom: 10px;
    }    

    #zone-table-header {
        width: 593px;
        /* margin-left: 8px; */
        grid-template-columns: 60px 126px 67px 142px;
    }

    .selected-row {
        margin-top: 0;
    }

    #zone-action-label {
        margin-left: 26px;
    }

    .zone-edit-cancel-btn {
        margin-left: 23px;
    }

    .selected-row, 
    .unselected-row {
        grid-template-columns: 53px 126px 66px 142px;
    }

    .selected-row 
    select {
        width: 86px;
    }  

    .selected-row 
    input {
        width: 120px;
    }
}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #zone-controls-row >
    .top-button-row {
        max-width: 340px;
        min-width: 340px;
    }

    #zone-table-header {
        grid-template-columns: 38px 120px 100px 142px;
        margin: 0 auto 0 10px;
    }

    #zone-table-wrapper {
        max-width: 340px;
        min-width: 340px;
    }

    #zone-table-wrapper >
    table {
        width: 340px;
        min-width: 340px;
    }

    
    #zone-table-wrapper >
    table >
    tbody {
        width: 340px;
        min-width: 340px;
        max-width: 340px;
    }

    #zone-table-wrapper 
    .selected-row,
    #zone-table-wrapper
    .unselected-row {
        grid-template-columns: 32px 120px 80px 142px;
    }

    #zone-table-wrapper
    .unselected-row {
        grid-gap: 7px;
    }

    #zone-table-wrapper 
    .selected-row 
    input {
        width: 110px;
    }
    
    #zone-table-wrapper 
    .selected-row 
    select {
        width: 80px;
    }


    .zone-edit-cancel-btn {
        margin-left: 5px;
    }

}