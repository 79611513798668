
#floors-container {
    position: relative;
    height: 100vh;
    overflow: scroll;
}

#floors-wrapper {
    height: calc(100vh - 180px);
    overflow: auto;
    max-width: 450px;
    margin: 0 auto;
}

#floors-wrapper >
.top-button-row {
    display: grid;
    grid-template-columns: auto 30px;
    grid-gap: 2px;
    margin: 10px 7px 0 auto;
    height: 50px;
    max-width: 450px;
    min-width: 360px;
}

.floor-inputs-1,
.floor-inputs-2 {
    font-size: 12pt;
    margin-bottom: 15px;
}

.floor-inputs-1 {
    display: grid;
    grid-template-columns: 230px 60px;
    text-align: left;
    margin: 10px 0;
}

#floors-container
.floor-name {
    margin: 5px 0;
    width: 210px;
}

#floors-container
.floor-level {
    margin: 5px 0;
}

#floors-container
.floor-level {
    width: 100px;
}

.floor-label-wrapper-2,
.floor-label-wrapper-3 {
    text-align: left;
    
}

.floor-label-wrapper-3 {
    display: grid;
    grid-template-columns: 118px 98px;
}

.form-input-label {
    margin-bottom: 5px;
}

.form-section-divider{
    margin-bottom: 10px;
}

.form-section-header {
    margin-top: 10px;
    margin-bottom: 5px;
}

.input-field {
    margin-top: 10px;
    margin-bottom: 5px;
}

.floor-inputs-2 {
    display: grid;
    grid-template-columns: 98px 20px 98px 100px;
    grid-template-rows: 32px;
    text-align: left;
    margin: 5px 0;
    height: 32px;
}

.input-section{
    margin-top: 20px;
    margin-bottom: 20px

}

.gps-label{
    margin-top: 10px;
}

#floors-container
.floor-height {
    width: 88px;
    margin: 0;
}

#floors-container
.floor-width {
    width: 88px;
    margin: 0;
}

#floors-container
.slash-divider {
    font-size: 14pt;
    color: #a2a9ad;
    width: 20px;
    margin: 0px 2px;
}

.floorplan-upload-btn {
    width: auto;
    height: 30px;
    color: white;
    background-color: #47404d;
    border: none;
    margin: 10px 16px 0 12px;
    cursor: pointer;
    font-size: 14px;
    padding: 2px 8px;
    border-radius: 7px;
}

#add-file,
#edit-file {
    width: 0;
    border-style: none;
    display: none;
}

.img-container {
    margin-top: 10px;
}

.img-container > img {
    height: 150px;
    overflow: hidden;
    width: 150px;
    align-content: center;
    margin-top: 10px;
}


@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 740px)
  and (-webkit-min-device-pixel-ratio: 2) {      
      #floors-wrapper >
      .top-button-row {
          display: grid;
          grid-template-columns: auto 30px;
          grid-gap: 2px;
          margin: 10px 7px 0 auto;
          height: 50px;
          max-width: 450px;
          min-width: 360px;
      }

}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #floors-wrapper {
        width: 300px;
    }

    #floors-wrapper > .top-button-row {
        grid-template-columns: 266px 30px;
    }

    #floor-table {
        max-width: 300px;
        min-width: 300px;
    }

    #floors-container >
    .add-edit-wrapper {
        width: 300px;
    }

    #floors-container >
    .add-edit-wrapper >
    .add-edit-header {
        width: 300px;
    }

    #floors-container >
    .add-edit-wrapper >
    .add-edit-form {
        width: 300px;
    }

    #floors-container .floor-level {
        width: 70px;
    }

    .floor-label-wrapper-3 {
        grid-template-columns: 98px 98px;
    }

    .floor-inputs-2 {
        grid-template-columns: 88px 10px 89px 100px;
    }

}
