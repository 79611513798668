#backup-wrapper {
    position: relative;
    height: 100%;
    overflow: hidden;
}

/* TABLE */
#backup-table,
#add-backup-form {
    width: 500px;
    margin: 25px auto;
}

#backup-table-header th {
    padding: 5px;
    text-align: left;
}

#backup-row:nth-child(even) {
    background-color: #f2f2f2;
}

#backup-row > .backup-col {
    /* border: 1px solid black; */
    padding: 5px;
    text-align: left;
}

#edit-delete-wrapper {
    display: flex;
    flex-direction: row;
}

#backup-row .delete-btn {
    height: 32px;
}

.table-btn {
    cursor: pointer;
}


/* MODALS */
#edit-device-name,
#add-device-name, 
#name-label {
    width: 160px;
    margin-right: 5px;
}

#input-MAC, 
#edit-device-MAC,
#add-device-MAC {
    width: 124px;
}

#import-backup-btn {
    min-width: 55px;
    cursor: pointer;
}
#create-backup-btn {
    min-width: 55px;
    cursor: pointer;
}

.device-label-wrapper1 {
    display: grid;
    grid-template-columns: 180px 110px;

    padding: 10px 30px 0px 30px;
}

.device-label-wrapper2 {
    display: grid;
    grid-template-columns: 185px 100px;

    padding: 10px 30px 0px 0px;
}

.MAC-label {
    width: 100px;
}

.device-label-wrapper2 >
.site-label, .user-label {
    margin-left: 4px;
}

.site-select {
    width: 180px;
    margin-right: 5px;
}

.site-select,
.patient-select {
    height: 38px;
}
