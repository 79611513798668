/* COLORS
header blue is #0096a9
safe green and submit button is #3dae2b
warning orange is #f5a800
alert red and cancel button is #ca3625
gray text is #808080
green buttons are #4fb346
gray buttons are #b9baba
gray input boxes are #dee0e2
extra action buttons are #47404d
*/

body {
    font-size: 12pt;
}

.App {
    height: calc(100vh - 32px);
}

#page-container {
    overflow: hidden;
}

.content-container {
    overflow: hidden;
    margin-bottom: 30px;
}

.top-button-row {
    display: grid;
    grid-template-columns: auto 30px;
    grid-gap: 2px;
    margin: 10px 5px 0 auto;
    height: 50px;
    max-width: 450px;
    min-width: 360px;
}

.add-btn {
    background-image: url('../images/greenplus.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    margin: auto 3px auto auto;
}

.table-label {
    font-size: 22pt;
    color: #808080;
    text-align: left;
    margin: 5px 0 10px 10px;
}

table {
    margin-left: 5px;
    margin-top: 10px;
    max-height: 75vh;
    max-width: 600px;
    min-width: 360px;
    border: none;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: auto;
}

tbody {
    max-width: 450px;
    min-width: 360px;
    font-size: 12pt;
}

tr {
    color: #000000;
}

tr:nth-child(even) {
    background-color: #f2f2f2;
}

tr:last-child {
    margin-bottom: 40px;
}

tr > .main-col {
    padding: 5px;
    text-align: left;
    width: 350px;
}

tr > .action-wrapper {
    display: flex;
    flex-direction: row;
}

tr > .action-wrapper-3 {
    width: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
}

tr > .action-wrapper-2 {
    width: 70px;
    display: flex;
    flex-direction: row;
}


td >
.manage-next-btn {
    height: fit-content;
    width: fit-content;
    padding: 10px 3px 10px 3px;
    margin: auto 2px;
    color: #fff;
    background-color: #a2a9ad;
    border-radius: 5px;
    font-size: 10pt;
    cursor: pointer;
}

td >
.table-btn,
.edit-btn,
.delete-btn {
    height: 35px;
    width: 35px;
    cursor: pointer;
}

.edit-btn {
    background-image: url('../images/edit.svg');
    background-repeat: no-repeat;
    margin: auto 2px;
}

.delete-btn {
    background-image: url('../images/trashcan.svg');
    background-repeat: no-repeat;
    margin: auto 2px;
}

button {
    border-radius: 5px;
}

.page-title {
    font-size: 2.2rem;
    font-weight: 500;
    color: #0096a9;
    margin-top: 20px;
    background: #ffffff;
    height: fit-content;
    min-height: 40px;
    width: 100%;
    border-bottom: 1px solid #000000;
    padding-top: 10px;
}

#modal-content {
    border: 1px solid black;
}

.modal-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 64px;
    background-color: #fff;
    color: #27AAE1;
    border-bottom: 1px solid #27AAE1;
}

#modal-content > #edit-device-form {
    padding: 10px 30px 40px 30px;
}

.top-buttons-wrapper {
    display: grid;
    grid-template-columns: 120px 120px;
    width: 250px;
    margin: 0px auto;
}

.setup-back-btn {
    width: fit-content;
    min-width: 64px;
    height: 30px;
    border-style: none;
    background-color: #0096a9;
    color: #fff;
    cursor: pointer;
    padding: 0 12px;
    margin-top: 12px;
}

.setup-add-btn {
    margin: 0px auto;
    color: white;
    border-style: none;
    height: 24px;
    background-color: #0196a9;
    border-radius: 5px;
    width: fit-content;
    min-width: 64px;
    cursor: pointer;
}

/* Cancel - Submit button group */
.cs-btn-group {
    display: grid;
    grid-template-columns: 64px 64px;
    grid-gap: 5px;
    justify-content: center;
    margin: 15px 0 40px auto;
}

.cs-btn {
    color: white;
    border-style: none;
    border-radius: 5px;
    height: 30px;
}

.cancel-btn {
    background-color: #ca3625;
    color: #ffffff;
}

.submit-btn {
    background-color: #3dae2b;
    color: #ffffff
}

.form-control {
    height: 32px;
    padding-left: 10px;
    /* border-style: none; */
    border-width: 1px;
    border-color: black;
}

/* add & edit forms */
.add-edit-wrapper {
    width: 360px;
    margin: 10px auto 0px auto;
}

.disabled-add-btn,
.disabled-edit-btn {
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: 11px 8px 0px auto;
}

.disabled-add-btn {
    background-image: url('../images/grayplus.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: 11px 8px 0px auto;
}

.disabled-edit-btn {
    background-image: url('../images/edit_gray.svg');
}

.add-edit-wrapper >
.add-edit-header {
    display: flex;
    flex-direction: row;
    text-align: left;
    font-size: 22pt;
    font-weight: 500;
    color: #3dae2b;
    margin-left: 8px;
    width: 360px;
}

.add-edit-wrapper >
.add-edit-form {
    width: 360px;
}

.add-edit-wrapper >
.add-edit-form >
input,
select {
    height: 32px;
    width: 360px;
    border: 1px solid gainsboro ;
    font-size: 12pt;
    color: #808080;
}


.add-edit-form >
select {
    font-size: 12pt;
    color: #808080;
    padding-left: 8px;
}


#duplicate-name-input {
    width: 360px;
    color: #808080;
}


.form-input-label {
    font-size: 12pt;
    font-weight: 500;
    text-align: left;
    margin: 5px 0 0 10px;
}

/* MEDIA QUERIES */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 740px)
  and (-webkit-min-device-pixel-ratio: 2) { 
    .top-button-row {
        margin: 10px 5px 0 auto;
    }
}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {
    
    .add-edit-wrapper > .add-edit-form > input, select {
        width: 300px;
    }   

}