#header-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 10;
}

#logo-container {
  border-bottom: 1pt solid #939598;
  box-shadow: 0.5px 0px 2px rgba(0, 0, 0, 0.1);
}

#logo {
  width: auto;
  height: 50px;
  margin: 10px 0;
}

#header-graphic {
  width: 100%;
  height: auto;
  margin-bottom: 5px;
}

.MuiAppBar-root {
  margin-top: -10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.MuiToolbar-root {
  min-height: 40px !important;
  padding: 0 !important;
  display: flex;
  justify-content: space-between;
}

.nav-button {
  text-transform: none !important;
  border: none !important;
  border-radius: 0 !important;
  padding: 8px 15px !important;
  font-size: 12pt !important;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: background-color 0.3s ease;
}

.nav-button::after {
  content: '';
  position: absolute;
  right: 0;
  top: 10%;
  height: 80%;
  width: 1px;
  background-color: white;
}

.nav-button:last-child::after {
  display: none;
}

.nav-button:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.nav-button.highlight {
  background-color: #4CAF50 !important;
}

.header-item { /* This is the class for the login buttons positioning and color */
  position: absolute;
  background-color: #BCBEC0;
  cursor: pointer;
  border-radius: 10px;
  padding: 5px 15px;
  color: #BCBEC0;
}

/* Logout modal styles */
.logout-header {
  font-size: 2rem;
  font-weight: 500;
  color: #27AAE1;
}

.log-message {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px auto;
  margin-top: 0;
}

.log-message p {
  margin: 0;
  color: #be1e2d;
  font-size: 1em;
  font-weight: 500;
}

#logout-modal-content {
  background-image: url(../../images/gradient03.svg);
  width: 100%;
  margin: '10px'
}

#logout-buttons {
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 40%;
}

#logout-btn,
#cancel-logout-btn {
  color: white;
  border: none;
  height: 64px;
  margin: 0 15%;
  position: absolute;
  font-size: 1.2rem;
  align-self: center;
}

#logout-btn {
  bottom: 16px;
  background-color: #be1e2d;
  align-self: center;
}

#cancel-logout-btn {
  bottom: 88px;
  background-color: #c1c1c1;
}