
.notifications-page-wrapper {
    height: calc(100vh - 182px);
    overflow: auto;
}

#notifications-selects-wrapper {
    display: grid;
    grid-template-columns: 360px;
    grid-gap: 4px;
    justify-content: center;
    padding-top: 20px;
}

.notification-select-group {
    display: grid;
    grid-template-rows: 20px 32px;
    text-align: left;
}

#notifications-site-select,
#notifications-user-select {
    height: 32px;
    width: 360px;
    font-size: 12pt;
    color: #000000;
    margin: 0 auto;
}

#notifications-site-select,
#notifications-user-select,
#duration-select {
    border: 2px solid gainsboro;
}

.notifications-select-label {
    text-align: left;
    padding-left: 5px;
    font-size: 12pt;
    font-weight: 500;
    height: 24px;
}

.shift-range-select-grp {
    display: grid;
    grid-template-columns: 220px 140px;
    grid-gap: 4px;
    justify-content: center;
}

.datetime-grp {
    display: flex;
    flex-direction: column;
}

#start-time-select {
    width: 220px;
}

#start-time-select >
.ant-calendar-picker {
    border: 2px solid gainsboro;
}

.ant-input {
    border: none;
    height: 24px;
    padding: 0 0 0 4px;
    font-size: 12pt;
    color: #000;
}

.shift-range-select-grp
.notifications-select-label {
    width: 140px;
}

.notification-select-group >
#duration-select {
    width: 138px;
    height: 32px;
    font-size: 12pt;
}

/* TABLE */

#notifications-table-container {
    display: grid;
    grid-template-rows: 32px 160px 32px;
    grid-gap: 8px;
    width: 360px;
    margin: 24px auto 0 auto;
}

.notifications-table-top-row {
    height: 32px;
    display: grid;
    grid-template-columns: 145px 86px;
    grid-gap: 114px;
}

.notifications-table-top-row >
#unselect-btn {
    width: 100px;
    height: 32px;
    background-color: #47404d;
    color: #fff;
    border-style: none;
}

#notifications-table-label {
    font-weight: 500;
    font-size: 12pt;
    text-align: left;
    margin-left: 5px;
}

#notifications-table-wrapper {
    height: 160px;
    width: 300px;
    margin: 0 auto;
    /* overflow-y: scroll;  */
}

#notifications-table {
    margin: 0 auto;
    text-align: left;
    width: 300px;
    min-width: 300px;
}

.notification-row {
    height: 32px;
    font-size: 12pt;
}

.notification-row >
input {
    height: 18px;
    width: 18px;
}

.notification-row >
label {
    font-size: 12pt;
    margin-left: 5px;
}

#notifications-buttons-container {
    width: 80px;
    margin: 0 0 0 auto;
}

#notifications-submit-btn {
    width: 80px;
    height: 32px;
    background-color:#3dae2b;
    border-style: none;
    color: white;
}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #notifications-selects-wrapper {
        grid-template-columns: 300px;
    }

    .notifications-select-label {
        width: 300px;
    }

    #notifications-site-select,
    #notifications-user-select {
        width: 300px;
    }
    
    .shift-range-select-grp {
        grid-template-columns: 195px 102px;
    }

    #start-time-select > 
    .ant-calendar-picker {
        width: 156px;
    }

    .notification-select-group > #duration-select {
        width: 102px;
        font-size: 10pt;
        font-weight: 500;
    }

    #notifications-table-container {
        width: 300px;
    }

    .notifications-table-top-row {
        grid-gap: 56px;
    }
}