#user-container {
    position: relative;
    height: 100vh;
    overflow: auto;
    /* overflow: hidden; */
}

#users-wrapper {
    height: calc(100vh - 178px);
    overflow: auto;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 30px;
}


#user-table
.main-col {
    width: 374px;
}

#user-row .delete-btn {
    height: 32px;
}

.password-toggle-btn {
    width: 194px;
    height: 30px;
    color: white;
    font-size: 14px;
    background-color: #47404d;
    border: none;
    margin: 10px 16px 0 16px;
    cursor: pointer;
    padding: 2px 8px;
    border-radius: 7px;
}


.user-input-label {
    margin: 5px 0 0 4px;
    font-size: 12pt;
    font-weight: 500;
    text-align: left;
}

.user-password-wrapper {
    width: 360px;
    display: grid;
    grid-template-columns: 177px 177px;
    grid-gap: 0 6px;
}

.user-password-input,
.user-password2-input {
    height: 32px;
    width: 175px;
    border: 1px solid gainsboro;
    font-size: 12pt;
    color: #a2a9ad;
}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #users-wrapper {
        width: 302px;
    }

    #users-wrapper > .top-button-row {
        grid-template-columns: 270px 30px;
    }

    #user-table {
        max-width: 300px;
        min-width: 300px;
    }

    #user-container >
    .add-edit-wrapper {
        width: 300px;
        height: calc(100vh - 181px);
        overflow: auto;
    }

    #user-container >
    .add-edit-wrapper >
    .add-edit-header {
        width: 300px;
    }

    #user-container >
    .add-edit-wrapper >
    .add-edit-form {
        width: 300px;
    }

    .user-pid-input,
    .user-alias-input,
    .user-username-input,
    .user-site-select,
    .user-role-select {
        width: 300px;
        font-size: 12pt;
    }

    .user-password-wrapper {
        width: 300px;
        grid-template-columns: 149px 149px;
        grid-gap: 0 4px;
    }

    #user-container
    .cs-btn-group {
        margin-bottom: 10px;
    }

    .user-password-wrapper>
    .user-password-input,
    .user-password2-input {
        width: 149px;
    }
}
