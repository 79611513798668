#calibration-container {
    position: relative;
    height: 100vh;
    overflow: auto;
}

#calibration-container >
.page-title {
    height: fit-content;
}

#calibration-container >
#calibration-wrapper {
    height: calc(100vh - 178px);
    overflow: auto;
    margin-bottom: 30px;
}

.calibration-controls,
.calibration {
    font-size: 12pt;
}
    
.calibration-controls {
    position: relative;
    align-content: center;
    display: grid;
    grid-template-rows: 53px 53px 53px 240px;
    grid-gap: 4px;
    margin: 6px auto 0 auto;
    width: 360px;
}

.calibration {
    position: relative;
    align-content: center;
    display: grid;
    grid-template-rows: 53px 74px;
    grid-gap: 4px;
    margin: 6px auto 0 auto;
    width: 360px;
}

/* .calibration >
.calibration-select {
    margin: 0 auto;
} */

.calibration-btn {
    height: 64px;
    width: 360px;
    margin: 10px auto 0 auto;
    color: #ffffff;
    font-size: 16pt;
    cursor: pointer;
    border: none;
    border-radius: 10px;
}

.calibration-label {
    text-align: left;
    padding-left: 5px;
    font-weight: 500;
    height: 21px;
}

.calibration-labels,
.calibration-select {
    width: 360px;
}

#zone-map-wrapper {
    height: calc(100vh - 550px); 
    margin: 0 auto;
}

#map-placeholder {
    height: 100%;
    width: 100%;
}

/* ZONE CALIBRATION ANALYTICS */
#calibration-analytics-section {
    text-align: center;
    
}

#calibration-analytics-section >
.calibration-analytics-header {
    font-size: 16pt;
    font-weight: 500;
    margin-top: 10px;
}

.lost-connection-messages {
    width: 360px;
    margin: 60px auto 0 auto;
    text-align: center;
}

.lcm1 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 16px;
}

.lcm2 {
    font-size: 1.2rem;
    font-weight: 500;
} 


/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #calibration-container >
    #calibration-wrapper {
        width: 300px;
        height: calc(100vh - 181px);
        overflow: auto;
        margin: 0 auto;
    }

    .calibration-labels,
    .calibration-select {
        width: 300px;
    } 

    .calibration-btn {
        width: 300px;
        margin: 10px 0 0 0;
    }

}