
#login-wrapper {
    position: relative;
}

.gradient-container {
    /* background-repeat: repeat-x;
    background-size: auto; */
    top: 0px;
    position: relative;
}

.blue-gradient {
    /* background-image: url('../../images/gradient01.svg'); */
    background-image: linear-gradient(#cceaee, #ffffff);
}

.red-gradient {
    /* background-image: url('../../images/gradient02.svg'); */
    background-image: linear-gradient(#f4d6d3, #ffffff);
}

#login-controls {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: 60px 90px 90px 64px 64px;
    width: 300px;
    margin: 0 auto 0 auto;
}

#title-container {
    border: 3pt black;
}

.page-title {
    color: #1C6CA5;
    position: relative;
    padding: 0;
    font-size: 1.7rem;
    background-color: transparent;
}

.login-message {
    width: 260px;
    display: grid;
    grid-template-rows: 20px 20px;;
    justify-content: left;
    align-content: left;
    margin: 20px 0 10px 0;
}

.login-error,
.error-message {
    text-align: left;
    margin-bottom: 12px;
}

.login-error {
    color: red;
    font-weight: 500;

}

.error-message {
    font-size: 10pt;
}

.login-input-label {
    text-align: left;
    font-size: 12pt;
    font-weight: 600;
    margin-bottom: 2px;;
}

#loginAuthUser,
#loginAuthPassword {
    border: 1.5px solid #b9baba;
    height: 32px;
    width: 300px;
    padding: 0 0 0 5px;
    font-size: 12pt;
    margin-bottom: 5px;
}

button {
    cursor: pointer;
}

#login-button,
#cancel-login-button {
    border-style: none;
    border-radius: 10px;
    height: 64px;
    width: 260px;
    color: white;
    margin: 0 auto;
    font-size: 1.2rem;
}

#login-button {
    background-color: #39B54A;
}

#cancel-login-button {
    background-color: #BCBEC0;
}