.App {
  text-align: center;
  height: calc(100vh - 64px);
  overflow: hidden;
}

body {
    background-repeat: no-repeat;
    background-size: cover;
    
}

#root {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: 250px;
    height: calc(100vh - 64px);
}