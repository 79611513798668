#preferences-wrapper {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.preference-group {
    display: flex;
    justify-content: center;
    width: 90vw;
    max-width: 360px;
    padding: 10px;
    margin: 10px auto;
    border: 2px solid #dee0e2;
    border-radius: 20px;
    background-color: white;
    cursor: pointer;
    font-weight: 500;
}

.preference-label {
    display: inline;
    text-align: right;
    margin-right: 5px;
}

.preference-uom {
    display: inline;
    text-align: left;
    margin-left: 5px;
}

.preference-minutes {
    margin-left: 4px;;
}

#interval-select {
    width: 134px;
    height: 32px;
    margin-left: 10px;
    margin-top: -10px;
    font-size: .8rem;
}

.setting-wrapper {
    width: 390px;
    /* height: 600px; */
    padding: 10px;
    margin: 0 auto;
    background-color: white;
}

.setting-header {
    font-size: 1.3em;
    font-weight: 500;
}

.setting-info {
    padding: 10px;
    text-align: left;
    color: slategray;
}

.setting-input-group {
    display: grid;
    grid-template-columns: 200px 44px 100px;
    margin: 20px;
    font-weight: 500;
}

.setting-input-group >
.setting-label{
    text-align: right;   
}    

.hours-input {
    width: 30px;
    margin-left: 5px;
}

.setting-input-group >
.setting-uom{
    text-align: left;   
}  

.setting-button-group {
    display: grid;
    grid-template-columns: 100px 100px;
    grid-gap: 10px;
    margin: 40px 50px 20px 80px;
    /* width: 360px; */
}

.setting-button {
    height: 32px;
    width: 100px;
    color: white;
    align-content: center;
    border: none;
    cursor: pointer;
}

.setting-cancel-button {
    background-color: red;
}

.setting-submit-button {
    background-color: green;
}
