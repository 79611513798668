#patient-container {
    position: relative;
    height: 100vh;
    overflow: auto;
    /* overflow: hidden; */
}

#patients-wrapper {
    position: relative;
    height: calc(100vh - 177px);
    overflow: auto;
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 30px;
}

.patient-alias, 
.patient-site {
    font-weight: 500;
    text-align: left;
    font-size: 12pt;
}

.patient-alias {
    padding-left: 5px;
}


#patient-row {
    text-align: left;
}

#patient-row >
.primary-col {
    width: 223px;
    padding-left: 5px;
}

#patient-row >
.secondary-col {
    width: 150px;
}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 320px)
and (max-width: 480px) {

    #patients-wrapper {
        width: 302px;
    }

    #patients-wrapper > .top-button-row {
        grid-template-columns: 268px 30px;
    }

    #patient-table {
        max-width: 300px;
        min-width: 300px;
    }

    #patient-container >
    .add-edit-wrapper {
        width: 300px;
    }

    #patient-container >
    .add-edit-wrapper >
    .add-edit-header {
        width: 300px;
    }

    #patient-container >
    .add-edit-wrapper >
    .add-edit-form {
        width: 300px;
    }

    #patient-container input, select {
        width: 300px;
    }


}