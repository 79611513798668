#site-container {
    position: relative;
    height: 100vh;
    overflow: auto;
}

#sites-wrapper {
    max-width: 450px;
    margin: 0 auto;
    margin-bottom: 30px;
}

#sites-wrapper > 
.top-button-row {
    display: grid;
    grid-template-columns: auto 30px 30px;
    grid-gap: 2px;
    margin: 10px 5px 0 auto ;
    height: 50px;
    max-width: 450px;
    min-width: 360px;
}

/* TABLE */
.site-table-label {
    font-size: 22pt;
    color: #a2a9ad;
    text-align: left;
    margin: 0 0 10px 10px;
}

.dup-site-btn {
    background-image: url('../../images/duplicate.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: auto 8px auto auto;

}

.add-site-btn {
    background-image: url('../../images/greenplus.svg');
    background-repeat: no-repeat;
    margin-right: 5px;
    width: 30px;
    height: 30px;
    margin: auto 0px auto 2px;
}

/* TABLE */
#site-table {
    max-height: calc(100vh - 250px);
    /* margin-bottom: 30px; */
}

#site-table
.main-col {
    max-width: 330px;
    overflow-wrap: break-word;
}

.disabled-dup-btn {
    background-image: url('../../images/duplicate_gray.svg');
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    margin: 11px 8px 0px auto;
}

.site-row {
    max-width: 450px;
    min-width: 360px;
}

/* ADD&EDIT FORMS */
#site-container
input,
select {
    width: 360px;
    margin: 4px 0 10px 0;
}

#site-container
select {
    padding-left: 10px;
    margin: 10px 0;
}


/* MEDIA QUERIES */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 740px)
  and (-webkit-min-device-pixel-ratio: 2) { 

    #sites-wrapper > 
    .top-button-row {
        display: grid;
        grid-template-columns: auto 30px 30px;
        grid-gap: 2px;
        margin: 10px 5px 0 auto ;
        height: 50px;
        max-width: 450px;
        min-width: 360px;
    }

    
    #site-table
    .main-col {
        max-width: 256px;
    }

}

/* Motorola G6 portrait and landscape */
@media only screen 
and (min-width: 300px)
and (max-width: 480px) {

    #sites-wrapper {
        width: 300px;
    }

    #sites-wrapper > .top-button-row {
        grid-template-columns: 236px 30px 30px;
    }

    #site-table {
        max-width: 300px;
        min-width: 300px;
    }

    #site-container >
    .add-edit-wrapper {
        width: 300px;
    }

    #site-container >
    .add-edit-wrapper >
    .add-edit-header {
        width: 300px;
    }

    #site-container >
    .add-edit-wrapper >
    .add-edit-form {
        width: 300px;
    }

    #site-container input, select {
        width: 300px;
    }

}