#login-header-wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 10;
}
#logo-container{
    border-bottom: 1pt solid #939598;
    box-shadow: .5px 0px 2px;
    
}

#logo{
    width: auto;
    height: 50px;
    margin: 10px 0;
}

#header-graphic {
    width: 100%;
    height: auto;
    margin-bottom: 0;
}


#back-arrow {
    height: 28px;
    width: 28px;
    cursor: pointer;
}

.menu-item {
    cursor: pointer;
}

.header-controls > img {
    height: 45px;
    width: 116px;
    margin: 6px 0px;
    cursor: pointer;
}

.log-message {
    height: 50px;
    width: 260px;
    display: flexbox;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    margin: 12px auto;
    /* background-color: #f8c0c0; */
}

.log-message
p {
    margin: 40px 0 0 10px;
    color: #be1e2d;
    font-size: 1em;
    font-weight: 500;
}

#login-modal-content,
#logout-modal-content {
    background-image: url(../../images/gradient03.svg);
    background-position-y: -30px;
}

#logout-btn,
#cancel-logout-btn {
    color: white;
    border-style: none;
    height: 40px;
}

#logout-btn {
    bottom: 132px;
    background-color: #be1e2d;
}

#cancel-logout-btn {
    bottom: 88px;
    background-color: #c1c1c1;
}

#authUser,
#authPassword,
#login-btn {
    margin: 0 24px;
    position: absolute;
}

#authUser {
    bottom: 152px;
}

#authPassword {
    bottom: 108px;
}

#authUser,
#authPassword {
    border: black;
    border-style: solid;
    border-width: 1px;
    height: 32px;
    width: 252px;
    padding: 0 0 0 5px;
}

#login-btn,
#logout-btn,
#cancel-logout-btn {
    background-color: #8cd087;
    color: white;
    border-style: none;
    bottom: 64px;
    height: 32px;
    width: 260px;
}

#cancel-login-btn {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: fit-content;
    height: 24px;
    color: black;
    border-style: none;
}

#login-form {
    display: grid;
    grid-template-rows: 96px 64px 32px 32px;
    grid-gap: 12px;
    padding: 12px;
}

#logout-btn,
#cancel-logout-btn {
    color: white;
    border-style: none;
}

#logout-btn {
    bottom: 132px;
    background-color: #ff6600;
}

#cancel-logout-btn {
    bottom: 88px;
    background-color: #8cd087;
}