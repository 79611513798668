.landing-wrapper {
    height: calc(100vh);
    background-color: #ffffff;
}

.close-btn {
    background-image: url(../../images/close_white.svg);
    background-size: 3vh 3vh;
    height: 3vh;
    width: 3vh;
    background-repeat: no-repeat;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.logo {
    background-size: 300px 100px;
    width: 300px;
    height: 100px;
    background-repeat: no-repeat;
    position: relative;
    top: 74px;
    margin: 0 auto;
}

.half-logo {
    background-image: url(../../images/ActivTrace\ Logo.png);
}

.full-logo {
    background-image: url(../../images/ActivTrace\ Logo.png);
}

.about-text {
    text-align: center;
    font-size: 10pt;
    color: #1C6CA5 ;
    position: relative;
    top: 116px;
    width: 70vw;
    margin: 0 auto;
    z-index: 10;
}

.begin-btn {
    position: relative;
    top: 116px;
    width: 267px;
    height: 64px;
    /* padding-top: 10px; */
    font-size: 1.2rem;
    margin: 0 auto;
    color: #ffffff;
    background-color: #4fb346;
    border: none;
    border-radius: 10px;
    cursor: pointer;
}

.landing-wrapper >
.globe,
.outer-rect,
.inner-rect,
.aster-logo {
    z-index: 10;
}

.landing-wrapper >
.bottom-filler {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 40px;
    background-color: #1C6CA5 ;
}

.landing-wrapper >
.globe {
    background-image: url(../../images/blue_globe.png);
    background-size: 60vh 50vh;
    width: 60vh;
    height: 50vh;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.landing-wrapper >
.outer-rect {
    height: 47vh;
    width: 30vh;
    background-color: powderblue;
    opacity: .5;
    position: absolute;
    bottom: 0;
    right: 0;
}

.landing-wrapper >
.inner-rect {
    height: 38vh;
    width: 20vh;
    background-color: aqua;
    opacity: .7;
    position: absolute;
    bottom: 0;
    right: 0;
}

.landing-wrapper >
.aster-logo {
    background-image: url(../../images/asterlabslogo@4x.png);
    background-size: 20vh 5vh;
    height: 5vh;
    width: 20vh;
    background-repeat: no-repeat;
    position: absolute;
    bottom: 5px;
}

/* Media queries */
/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2) {

    .about-text {
        font-size: 10pt;

    }

  }
