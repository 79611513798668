#diagnostics-container {
    position: relative;
    height: 100vh;
    overflow: scroll;
    font-size: 12pt;
}

#diagnostics-selects {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: 10px auto;
    text-align: left;
}

#diagnostics-selects >
.diagnostics-select-label {
    font-weight: 500;
}

#diagnostics-selects >
.diagnostics-site-select {
    width: 200px;
    height: 36px;
}

.diag-devices-wrapper {
    overflow: auto;
    max-width: 600px;
    margin: 0 auto;
}

.diag-devices-table-lbl {
    margin-top: 20px;
    margin-bottom: 1em;
    font-size: 1.5rem;
    font-weight: 500;
    margin: 12px 0 20px 0;
}

#diag-device-table {
    margin: 0 auto;
    max-width: 600px;
}

#diag-device-table >
thead >
tr {
    margin: 0 auto;
    max-width: 600px;
}

.diag-device-name-lbl,
.diag-device-status-lbl,
.diag-last-connect-lbl {
    font-size: 12pt;
    font-weight: 500;
    text-align: left;
    width: 200px;
}

.diag-device-row {
    text-align: left;
    max-width: 600px;
    min-width: 360px;
}

td.diag-device-name, 
td.diag-device-status, 
td.diag-last-connect {
    width: 200px;
    text-align: left;
}

td.diag-device-status {
    display: flex;
    flex-direction: row;
    align-content: center;
}

td.diag-device-status >
.device-led {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    margin: 3px 3px 0 0;
}

td.diag-device-status >
.status-text {
    margin-left: 10px;
}

#diagnostics-wrapper {
    width: 600px;
    background-color: white;
    margin: 12px auto;
    padding: 12px;
}

#diagnostics-wrapper >
table {
    padding-bottom: 20px;
}

.diag-table-title {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 12px 0 20px 0;
}

.calibrations-table {
    max-height: none;
    max-width: none;
    min-width: 0;
    overflow-y: inherit;
    overflow-x: inherit;
}

.calibrations-table >
thead >
.calibrations-table-hdr {
    margin-bottom: 10px;
}

.calibrations-table,
.bssid-table {
    border: none;
}

.calibrations-table,
.bssid-table >
tbody {
    font-size: 1em;
    font-weight: 400;
    text-align: left;
}

.calibrations-table-hdr,
.zone-row {
    display: grid;
    grid-template-columns: 170px 185px 130px 64px;
    margin-left: 18px;
}

.reset-button {
    color: white;
    background-color: red;
    text-align: center;
    cursor: pointer;
    border: none
}

#diagnostics-wrapper
button {
    height: 30px;
    width: 75px;
    color: white;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 5px;
}

.zone-quality-message {
    display: flex;
    margin-left: 40px;
    color: red;
    font-weight: 500;
}

.bssid-quality-message {
    display: flex;
    margin-left: 60px;
    color: red;
    font-weight: 500;
}

.bssid-table {
    margin: 0 0 20px 16px;
}

.bssid-row:nth-child(even) {
    background-color: #f2f2f2;
}

.bssid-table-hdr,
.bssid-row {
    display: grid;
    grid-template-columns: 140px 80px 80px 80px 80px 80px;
    margin-left: 40px;
    margin-top: 4px;
    margin-bottom: 0;
}

.left-justify {
    text-align: left;
}

#modal-content {
    border: none;
}
