.survey-report-container {
    position: relative;
    overflow-y: auto;
}

.survey-reports-wrapper {
    position: relative;
    height: calc(100vh - 250px);
    overflow-y: auto;
}

.survey-report-wrapper {
    margin: 32px auto;
    width: 360px;
}

.survey-report-wrapper >
.survey-report-header,
.survey-report-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    font-size: 12pt;
    text-align: left;
}

.survey-report-wrapper >
.survey-report-header {
    font-weight: 500;
    margin-bottom: 10px;
}
/* 
.survey-report-content {
    max-height: calc(100vh - 280px);
    overflow-y: scroll;
} */

.survey-user-days {
    text-align: right;
    margin-right: 40px;
}

.days-note {
    position: absolute;
    bottom: 30px;
}

.csv-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: 10px auto 0 auto;
    height: 64px;
    width: 300px;
    font-size: 1.2rem;
    cursor: pointer;
    background-color: #47404d;
    color: #FFFFFF;
    border: none;
    border-radius: 10px;
}


/* MEDIA QUERIES */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 740px)
  and (-webkit-min-device-pixel-ratio: 2) { 

    .survey-report-wrapper {
        width: 300px;
    }

}