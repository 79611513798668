.Collapsible__trigger {
    background: mediumseagreen;
    color: white;
    padding: 5px;
    /* margin: 40px; */
    border-radius: 5px;
    cursor: pointer;
}

.calibrations-table
.Collapsible {
    margin-top: 20px;
}